import React from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import Nav from "./src/components/nav";

import "./src/styles/index.scss";
import "katex/dist/katex.min.css";
import Clock from "./src/components/clock";
import FooterNav from "./src/components/footerNav";

if ("scrollRestoration" in window.history) {
  // Back off, browser, I got this...
  window.history.scrollRestoration = "manual";
}

const scrollPositionState = {
  nextScrollPosition: [0, 0],
};

export const wrapPageElement = ({ element, props: { location } }) => {
  const isPrivate = location.pathname.includes("private");

  return (
    <div className="wrapper">
      <Nav hideNav={isPrivate}>
        <AnimateSharedLayout type="crossfade">
          <AnimatePresence
            exitBeforeEnter
            onExitComplete={() => {
              window.scrollTo(
                ...(scrollPositionState.nextScrollPosition || [0, 0]),
                true
              );
            }}
          >
            <motion.div
              key={location.pathname}
              animate="enter"
              exit="exit"
              transition={{
                ease: "easeInOut",
              }}
            >
              <main className="wrapper__inner"> {element}</main>
              <footer className="footer">
                <section className="section">
                  <div className="block-centered">
                    <Clock />
                  </div>
                </section>
                <section className="section">
                  <FooterNav />
                </section>
              </footer>
            </motion.div>
          </AnimatePresence>
        </AnimateSharedLayout>
      </Nav>
    </div>
  );
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  scrollPositionState.nextScrollPosition = getSavedScrollPosition(location);
  return false;
};
